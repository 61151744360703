import JEIcon from "../../images/JE-talent.png";
import {
  TopLine,
  AgentWrap,
  JETextWrap,
  JEImg,
  SocialLink,
} from "./InfoElements";
import { MdEmail, MdPhoneIphone } from "react-icons/md";
import { FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

export const homeObjOne = {
  id: "demos",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: "Demos",
  useRouterLink: false,
  useArrow: true,
  buttonLink: "contact",
  buttonLabel: "Like what you hear?",
  imgStart: false,
  img: require("../../images/svg-pride.svg").default,
  alt: "pride icon",
  primary: true,
  darkText: true,
  addTopPadding: true,
  showAudio: true,
};

const aboutMe = `As a queer, non-binary Asian-American, I've been acting for much of my life to fit in, basically stepping into a character that isn't fully me.
But now that I'm proud of my identity, I can confidently bring my authentic self to life and work. I use my voice to advocate for those left unheard and to help people
feel less alone by giving them someone they can relate to. Although I'm Asian, I speak Spanish and am working on embracing my culture more by learning Mandarin.
I have experience in law and software engineering and have diverse interests from rock climbing to flying planes, which allows me to enthusiastically dive into many
different types of copy. I'm determined, energetic, and an adventurous nerd, going from completing ultramarathons in San Francisco and London to playing and narrating board
game campaigns that last even longer. I love research and learning, building worlds that people can get immersed in, doing escape rooms and telling people all about them
(without spoilers), and I'm not afraid to go out of my comfort zone and try something new. My voice is youthful, smart, friendly, and versatile, and I'm excited to
collaborate on projects to make your vision and story come to life.`;

export const homeObjTwo = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "they/them",
  headline: "About Me",
  description: aboutMe,
  useRouterLink: false,
  buttonLink: "demos",
  buttonLabel: "Listen Instead!",
  imgStart: true,
  img: require("../../images/headshot.jpg").default,
  alt: "profile picture",
  primary: true,
  darkText: true,
};

export const homeObjThree = {
  id: "contact",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headline: "Contact Me",
  description: (
    <div>
      <TopLine>Business Contact</TopLine>
      <p>
        <MdEmail /> livvie.lin@gmail.com
      </p>
      <p>
        <MdPhoneIphone /> 415-508-8087
      </p>
      <br />
      <TopLine>Social Media</TopLine>
      <p>
        <FaInstagram />{" "}
        <SocialLink
          href="//www.instagram.com/livvielinsta"
          target="_blank"
          aria-label="Instagram"
        >
          livvielinsta
        </SocialLink>
      </p>
      <p>
        <FaTwitter />{" "}
        <SocialLink
          href="//www.twitter.com/livvielin"
          target="_blank"
          aria-label="Twitter"
        >
          livvielin
        </SocialLink>
      </p>
      <p>
        <FaLinkedin />{" "}
        <SocialLink
          href="//www.linkedin.com/in/livvielin"
          target="_blank"
          aria-label="LinkedIn"
        >
          livvielin
        </SocialLink>
      </p>
      <br />
      <TopLine>Voiceover Representation</TopLine>
      <AgentWrap>
        <JEImg src={JEIcon} alt="JE Talent icon" />
        <JETextWrap>
          <p>
            <b>JE Talent</b>
          </p>
          <p>San Francisco</p>
          <p>Cal Grant</p>
          <p>cal@jetalent.com</p>
        </JETextWrap>
      </AgentWrap>
    </div>
  ),
  useRouterLink: true,
  buttonLink: "/friends",
  buttonLabel: "My voice not right? Check out this amazing talent",
  imgStart: true,
  img: require("../../images/tetris.jpg").default,
  alt: "photo of myself in front of tetris wall and arcade machine",
  primary: true,
  darkText: true,
  addTopPadding: true,
};
