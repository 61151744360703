import React from "react";
import Friends from "../components/Friends";
import ScrollToTop from "../components/ScrollToTop";

const FriendsPage = () => {
  return (
    <>
      <ScrollToTop />
      <Friends />
    </>
  );
};

export default FriendsPage;
