import React, { useState } from "react";
import { Button, RouteButton } from "../ButtonElements";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  ArrowForward,
  ArrowRight,
  DemoTitle,
} from "./InfoElements";
import AboutMe from "../../demos/about-me.mp3";
import AnimationDemo from "../../demos/animation-demo.mp3";
import CommercialDemo from "../../demos/commercial-demo.mp3";
import CommercialDemoExtended from "../../demos/commercial-demo-extended.mp3";
import ElearningDemo from "../../demos/elearning-demo.mp3";
import TelephonyDemo from "../../demos/telephony-demo.mp3";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  darkText,
  description,
  useRouterLink,
  useArrow,
  buttonLink,
  buttonLabel,
  img,
  alt,
  primary,
  addTopPadding,
  showAudio,
}) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <InfoContainer addTopPadding={addTopPadding} lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                <TopLine>{topLine}</TopLine>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  {useRouterLink ? (
                    <RouteButton
                      to={buttonLink}
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={primary ? 1 : 0}
                    >
                      {buttonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
                    </RouteButton>
                  ) : (
                    <Button
                      to={buttonLink}
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                      smooth={true}
                      duration={500}
                      spy={true}
                      exact="true"
                      offset={-80}
                      primary={primary ? 1 : 0}
                    >
                      {buttonLabel}
                      {useArrow ? (
                        hover ? (
                          <ArrowForward />
                        ) : (
                          <ArrowRight />
                        )
                      ) : undefined}
                    </Button>
                  )}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              {showAudio ? (
                <div>
                  <DemoTitle>Commercial Demo</DemoTitle>
                  <audio controls title="Commercial Demo - Livvie Lin">
                    <source src={CommercialDemo} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <DemoTitle>Animation Demo</DemoTitle>
                  <audio controls title="Animation Demo - Livvie Lin">
                    <source src={AnimationDemo} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <DemoTitle>Extended Commercial Demo</DemoTitle>
                  <audio controls title="Extended Commercial Demo - Livvie Lin">
                    <source src={CommercialDemoExtended} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <DemoTitle>eLearning Demo</DemoTitle>
                  <audio controls title="eLearning Demo - Livvie Lin">
                    <source src={ElearningDemo} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <DemoTitle>Telephony Demo</DemoTitle>
                  <audio controls title="Telephony Demo - Livvie Lin">
                    <source src={TelephonyDemo} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                  <DemoTitle>About Me</DemoTitle>
                  <audio controls title="About Me - Livvie Lin">
                    <source src={AboutMe} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ) : (
                <ImgWrap>
                  <Img src={img} alt={alt} />
                </ImgWrap>
              )}
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
