import React from "react";
import IconMic from "../../images/svg-mic.svg";
import IconConnected from "../../images/svg-connected.svg";
import IconCoding from "../../images/svg-coding.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServiceElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Home Studio</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          {/* Icons from undraw.co */}
          <ServicesIcon src={IconMic} />
          <ServicesH2>Equipment</ServicesH2>
          <ServicesP>Rode NT1</ServicesP>
          <ServicesP>Scarlett 2i2 3rd Gen</ServicesP>
          <ServicesP>Beyerdynamic DT 770</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconConnected} />
          <ServicesH2>Connectivity</ServicesH2>
          <ServicesP>Source Connect</ServicesP>
          <ServicesP>Phone Patch, Skype</ServicesP>
          <ServicesP>Zoom, Meet, Discord</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IconCoding} />
          <ServicesH2>Software</ServicesH2>
          <ServicesP>TwistedWave</ServicesP>
          <ServicesP>Audacity</ServicesP>
          <ServicesP>ocenaudio</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
