import React from "react";
import {
  Container,
  Icon,
  FriendsContent,
  FriendsPageWrap,
  FriendsWrapper,
  FriendsCard,
  FriendsList,
  FriendsH1,
  FriendsHeader,
  FriendItem,
  FriendLink,
} from "./FriendsElements";

const Friends = () => {
  return (
    <>
      <Container>
        <FriendsPageWrap>
          <Icon to="/">liv</Icon>
          <FriendsContent>
            <FriendsH1>Livvie's Coaches and Colleagues</FriendsH1>
            <FriendsWrapper>
              <FriendsCard>
                <FriendsHeader>
                  Immense gratitude to my coaches who have made an impact and
                  supported me on my journey:
                </FriendsHeader>
                <FriendsList>
                  <FriendItem>
                    <FriendLink
                      href="//www.imdb.com/name/nm0335291"
                      target="_blank"
                      aria-label="Go to Cal's website"
                    >
                      Calum Grant
                    </FriendLink>{" "}
                    - commercial
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.craigleethomas.com"
                      target="_blank"
                      aria-label="Go to Craig's website"
                    >
                      Craig Lee Thomas
                    </FriendLink>{" "}
                    - commercial
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.thevoiceactorstudio.com/events-2"
                      target="_blank"
                      aria-label="Go to Melissa's website"
                    >
                      Melissa Moats
                    </FriendLink>{" "}
                    - commercial
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.cissyjones.com"
                      target="_blank"
                      aria-label="Go to Cissy's website"
                    >
                      Cissy Jones
                    </FriendLink>{" "}
                    - character
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.heykirstenday.com"
                      target="_blank"
                      aria-label="Go to Kirsten's website"
                    >
                      Kirsten Day
                    </FriendLink>{" "}
                    - character
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.justaskjimvo.studio/"
                      target="_blank"
                      aria-label="Go to Jim's website"
                    >
                      Jim Edgar
                    </FriendLink>{" "}
                    - audio
                  </FriendItem>
                </FriendsList>
              </FriendsCard>
              <FriendsCard>
                <FriendsHeader>
                  My voice not right for your project? No problem! Perhaps some
                  of these other voiceover artists are:
                </FriendsHeader>
                <FriendsList>
                  <FriendItem>
                    <FriendLink
                      href="//www.bespokevo.com"
                      target="_blank"
                      aria-label="Go to Marissa's website"
                    >
                      Marissa Delory
                    </FriendLink>{" "}
                    - clear, comforting, passionate
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.megantrout.com"
                      target="_blank"
                      aria-label="Go to Megan's website"
                    >
                      Megan Trout
                    </FriendLink>{" "}
                    - savvy, quirky, cool
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.eurienam.com/"
                      target="_blank"
                      aria-label="Go to Eurie's website"
                    >
                      Eurie Nam
                    </FriendLink>{" "}
                    - young, fresh, edgy
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.vickschutz.com/"
                      target="_blank"
                      aria-label="Go to Vick's website"
                    >
                      Vick Schutz
                    </FriendLink>{" "}
                    - trustworthy, confident, witty
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.kathykingmanvo.com"
                      target="_blank"
                      aria-label="Go to Kathy's website"
                    >
                      Kathy Kingman
                    </FriendLink>{" "}
                    - smooth, energetic, confident
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.marksolumvo.com"
                      target="_blank"
                      aria-label="Go to Mark's website"
                    >
                      Mark Solum
                    </FriendLink>{" "}
                    - warm, witty, father figure
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.lindyflowers.com"
                      target="_blank"
                      aria-label="Go to Lindy's website"
                    >
                      Lindy Flowers
                    </FriendLink>{" "}
                    - trustworthy, sophisticated, genuine
                  </FriendItem>
                  <FriendItem>
                    <FriendLink
                      href="//www.christysummersvo.com"
                      target="_blank"
                      aria-label="Go to Christy's website"
                    >
                      Christy Summers
                    </FriendLink>{" "}
                    - bright, fun, animated
                  </FriendItem>
                </FriendsList>
              </FriendsCard>
            </FriendsWrapper>
          </FriendsContent>
        </FriendsPageWrap>
      </Container>
    </>
  );
};

export default Friends;
