import "./App.css";
import Home from "./pages";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FriendsPage from "./pages/friends";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  html {
    --color-text-darkest: #010606; // black
    --color-text-dark: #534d56; // dark gray
    --color-text-mid: #d9d9d9; // mid gray
    --color-text-light: #f5f5f5; // light gray
    --color-text-lightest: #fff; // white
    --color-background: #ccf3f5; // light blue
    --color-accent: #a8c0f0; // darker blue
    --color-primary: #fc7753; // orange
    --color-secondary: #1b998b; // teal
  }
`;

function App() {
  return (
    <Router>
      <GlobalStyles />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/friends" component={FriendsPage} exact />
      </Switch>
    </Router>
  );
}

export default App;
