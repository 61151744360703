import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  background: var(--color-accent);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 420px;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    height: 550px;
  }
`;

export const HeroContentRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col2" "col1";
  }
`;

export const Column1 = styled.div`
  margin-top: 230px;
  padding: 0 15px;
  grid-area: col1;

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }
`;

export const Column2 = styled.div`
  margin-top: 100px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    margin-top: 160px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 200px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 8px 0;
  padding-right: 0;
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: var(--color-text-dark);
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: var(--color-text-dark);
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;
