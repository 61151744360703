import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const InfoContainer = styled.div`
  color: var(--color-text-lightest);
  background: ${({ lightBg }) =>
    lightBg ? "var(--color-background)" : "var(--color-accent)"};
  padding-top: ${({ addTopPadding }) => (addTopPadding ? "60px" : "0")};

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 24px;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 32px;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 16px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--color-text-light)" : "var(--color-text-dark)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ darkText }) =>
    darkText ? "var(--color-text-darkest)" : "var(--color-text-light)"};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 420px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  border: 5px solid var(--color-secondary);
  border-radius: 100px;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const AgentWrap = styled.div`
  width: 100%;
  white-space: nowrap;
`;

export const JEImg = styled.img`
  width: 40%;
  vertical-align: middle;
`;

export const JETextWrap = styled.div`
  display: inline-block;
  width: 40%;
  vertical-align: middle;
  margin-left: 40px;
`;

export const SocialLink = styled.a`
  color: var(--color-text-darkest);
  text-decoration: none;
`;

export const DemoTitle = styled.p`
  color: var(--color-text-darkest);
  margin: 5px 0;
`;
