import React, { useState } from "react";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  HeroContentRow,
  Img,
  ImgWrap,
  Column1,
  Column2,
} from "./HeroElements";
import IconChibi from "../../images/icon-transparent.png";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const alt = "Chibi icon";

  return (
    <HeroContainer id="home">
      <HeroContentRow>
        <Column1>
          <ImgWrap>
            <Img src={IconChibi} alt={alt} />
          </ImgWrap>
        </Column1>
        <Column2>
          <HeroH1>Livvie Lin</HeroH1>
          <HeroP>Voice Actor</HeroP>
          <HeroBtnWrapper>
            <Button
              to="contact"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Let's collaborate {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </Column2>
      </HeroContentRow>
    </HeroContainer>
  );
};

export default HeroSection;
