import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";

export const Button = styled(LinkScroll)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary ? "var(--color-primary)" : "var(--color-secondary)"};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) =>
    dark ? "var(--color-text-light)" : "var(--color-text-lightest)"};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary ? "var(--color-secondary)" : "var(--color-primary)"};
  }
`;

export const RouteButton = styled(LinkRouter)`
  border-radius: 50px;
  background: ${({ primary }) =>
    primary ? "var(--color-primary)" : "var(--color-secondary)"};
  white-space: normal;
  max-width: 100%;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) =>
    dark ? "var(--color-text-light)" : "var(--color-text-lightest)"};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) =>
      primary ? "var(--color-secondary)" : "var(--color-primary)"};
  }
`;
